<template>
    <div>
        <v-btn
            text
            @click.prevent="openDialog"
            outlined
            class="btn btn-lg btn-add-main"
            style="background:transparent;"
        >
            <i class="fas fa-plus-circle"></i> Add New
        </v-btn>

        <v-btn

            :to="{ name: 'customers-list'}"
            outlined
            class="btn btn-lg btn-secondary-main"
            style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>

        <KTPortlet v-bind:title="'Customers Address'">
            <template v-slot:body>
                <v-app>
                    <v-row>

                        <v-col class cols="12" md="12" sm="12">
                            <v-data-table hide-default-footer :headers="headers" :items="customersAddress">
                                <!--                                <template v-slot:item.is_active="data">-->
                                <!--                                    <i-->
                                <!--                                        v-if="data.item.is_active"-->
                                <!--                                        class="kt-nav__link-icon flaticon2-check-mark text-success"-->
                                <!--                                    ></i>-->
                                <!--                                    <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>-->
                                <!--                                </template>-->
                                <!--                        -->
                                <template v-slot:item.action="data">
                                    <div class="kt-widget__toolbar">
                                        <a
                                            href="#"
                                            class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                            data-toggle="dropdown"
                                        >
                                            <i class="flaticon-more-1"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                            <ul class="kt-nav">
                                                <li class="kt-nav__item">
                                                    <a href @click.prevent="editLocation(data.item)"
                                                       class="kt-nav__link">
                                                        <i class="kt-nav__link-icon flaticon-edit"></i>
                                                        <span class="kt-nav__link-text">Edit</span>
                                                    </a>
                                                </li>

                                                <li class="kt-nav__item">
                                                    <a
                                                        href="#"
                                                        @click.prevent="deleteAddress(data.item.id)"
                                                        class="kt-nav__link"
                                                    >
                                                        <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>
                                                        <span class="kt-nav__link-text">Delete</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-dialog scrollable v-model="dialog" persistent max-width="650px">
                            <v-card>
                                <v-card-title>
                                    <span class="headline">Your Address</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-autocomplete
                                                        v-model="address.country_id"
                                                        :items="countries"
                                                        :loading="loading"
                                                        hide-no-data
                                                        hide-selected
                                                        item-text="title"
                                                        item-value="id"
                                                        label="Find Your Country"
                                                        placeholder="Start typing to Search your place"
                                                        prepend-inner-icon="mdi-database-search"
                                                        outlined
                                                        densed
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12"  v-if="address.country_id == 155">
                                                <v-autocomplete
                                                    v-model="model"
                                                    :items="items"
                                                    :loading="isLoading"
                                                    :search-input.sync="search"
                                                    hide-no-data
                                                    hide-selected
                                                    outlined
                                                    item-text="Description"
                                                    item-value="area"
                                                    @change="placechange"
                                                    label="Find Your Place"
                                                    placeholder="Start typing to Search your place"
                                                    prepend-inner-icon="mdi-database-search"
                                                    return-object
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="4" md="4">
                                                <v-text-field
                                                    label="Address Line1"
                                                    v-model="address.address_line_1"
                                                    required
                                                    outlined
                                                    hint="Road or Tole Name"
                                                    persistent-hint
                                                ></v-text-field>
                                                <p
                                                    class="text-danger font-weight-bold"
                                                    v-if="$v.address.address_line_1.$error"
                                                >{{validationMessage.address_line_1}}</p>
                                            </v-col>
                                            <v-col cols="4" md="4">
                                                <v-text-field
                                                        outlined
                                                        label="Address Line 2"
                                                              v-model="address.description"
                                                              required></v-text-field>
                                            </v-col>
                                            <!-- <v-col cols="2" md="2">
                                              <v-text-field label="Post Code" v-model="address.post_code" required></v-text-field>
                                            </v-col>-->
                                            <v-col cols="4" md="4">
                                                <v-combobox
                                                    v-model="address.area"
                                                    :items="areas"
                                                    hide-selected
                                                    label="Area"
                                                    persistent-hint
                                                    outlined                                                >
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    No results matching "
                                                                    <strong>{{ search }}</strong>". Press
                                                                    <kbd>enter</kbd> to create a new one
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-combobox>
                                                <p
                                                    class="text-danger font-weight-bold"
                                                    v-if="$v.address.area.$error"
                                                >{{validationMessage.area}}</p>
                                            </v-col>
                                            <v-col cols="4" md="4">
                                                <v-combobox
                                                    v-model="address.city"
                                                    :items="cities"
                                                    hide-selected
                                                    outlined
                                                    label="City"
                                                    persistent-hint
                                                >
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    No results matching "
                                                                    <strong>{{ search }}</strong>". Press
                                                                    <kbd>enter</kbd> to create a new one
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-combobox>
                                                <p
                                                    class="text-danger font-weight-bold"
                                                    v-if="$v.address.city.$error"
                                                >{{validationMessage.city}}</p>
                                            </v-col>
                                            <v-col cols="4" md="4">
                                                <v-combobox
                                                    v-model="address.state"
                                                    :items="states"
                                                    hide-selected
                                                    label="State"
                                                    outlined
                                                    autocomplete="off"
                                                    persistent-hint
                                                >
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    No results matching "
                                                                    <strong>{{ search }}</strong>". Press
                                                                    <kbd>enter</kbd> to create a new one
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-combobox>
                                                <p
                                                    class="text-danger font-weight-bold"
                                                    v-if="$v.address.state.$error"
                                                >{{validationMessage.state}}</p>
                                            </v-col>

                                            <v-col cols="4" md="4">
                                                <v-combobox
                                                    v-model="address.type"
                                                    :items="addressOptions"
                                                    hide-selected
                                                    outlined
                                                    label="Address Type"
                                                    autocomplete="off"
                                                    persistent-hint
                                                >
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    No results matching "
                                                                    <strong>{{ search }}</strong>". Press
                                                                    <kbd>enter</kbd> to create a new one
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-combobox>

                                                <p
                                                    class="text-danger font-weight-bold"
                                                    v-if="$v.address.type.$error"
                                                >{{validationMessage.type}}</p>
                                            </v-col>
                                        </v-row>

                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                                    <v-btn color="blue darken-1" text @click.prevent="save">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-app>
            </template>
        </KTPortlet>
    </div>
</template>
<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import WebsiteService from "@/services/Websites/WebsiteService";
    import WebsiteCustomerService from "@/services/store/EcommerceCustomerAddressService";
    import userAddressService from "@/services/user/UserAddressService";
    import {required} from "vuelidate/lib/validators";

    const websiteService = new WebsiteService();

    const WebsiteCustomer = new WebsiteCustomerService();

    const UserAddressService = new userAddressService();
    import CountryService from "@/services/address/CountryService";

    const country = new CountryService();
    export default {
        name: "customer-address",
        validations: {
            address: {
                address_line_1: {required},
                city: {required},
                area: {required},
                state: {required},
                type: {required}
            }
        },
        data: () => {
            return {
                loading: false,
                badge_color: null,
                search: {},
                edit: false,
                count: {},
                dialog: false,
                customer: {
                    email: null,
                    first_name: null,
                    last_name: null,
                    is_active: null,
                    phone: null,
                },
                customersAddress: [],
                websites: [],
                headers: [
                    {text: "Country", value: "country"    ,align: "start", sortable: false,},
                    {text: "State", value: "state"},
                    {text: "City", value: "city"},
                    {text: "Area", value: "area"},
                    {text: "Address Line 1", value: "address_line_1"},
                    {text: "Address Line 2", value: "address_line_2"},
                    {text: "Address type", value: "type"},
                    {text: "Action", value: "action"}
                ],
                addresses: "",
                addressOptions: ["home", "office", "billing", "shipping"],
                dialog: false,
                search: null,
                edit: false,
                showMaps: false,
                descriptionLimit: 60,
                entries: [],
                isLoading: false,
                model: null,
                center: {lat: 28.3949, lng: 84.124},
                cities: [],
                areas: [],
                states: [],
                countries: [],

                position: {lat: 28.253342268986668, lng: 84.93813695937502},
                markers: [
                    {
                        position: {lat: 27.6711212, lng: 85.3446311}
                    }
                ],
                place: null,
                validationMessage: {
                    address_line_1: "Address 1 is required",
                    city: "City is required",
                    area: "Area is required",
                    state: "State is required",
                    type: "Address is required"
                },
                address: {
                    address_line_1: "",
                    address_line_2: "",
                    city: "",
                    area: "",
                    state: "",
                    province: "",
                    type: "",
                    geolocation_id: "",
                    geo_lat: "",
                    geo_long: ""
                }
            };
        },
        components: {
            KTPortlet
        },
        methods: {
            getAllUserAddresses() {
                UserAddressService.getAddressesByUser().then(res => {
                    this.addresses = res.data;
                });
            },
            getCountries(){
                country.all().then(res=>{
                    this.countries = res.data;
                })
            },
            closeDialog() {
                this.dialog = false;
                this.edit = false;
            },
            openDialog() {
                this.dialog = true;
                this.edit = false;
            },
            placechange() {
                this.address.city = this.model.city;
                this.address.area = this.model.area;
                this.address.state = this.model.state;
                this.address.province = this.model.province;
                this.address.geolocation_id = this.model.id;
                this.address.geo_lat = this.model.geo_lat;
                this.address.geo_long = this.model.geo_long;
                this.position.lat = this.model.geo_lat;
                this.position.lng = this.model.geo_long;
                this.center = this.position;
            },
            setDescription(description) {
                this.description = description;
            },
            setPlace(place) {
                this.place = place;
            },
            changePosition(e) {
                this.position.lat = e.latLng.lat();
                this.position.lng = e.latLng.lng();
                this.address.geo_lat = e.latLng.lat();
                this.address.geo_long = e.latLng.lng();
                this.center = this.position;
            },
            usePlace(place) {
                if (this.place) {
                    this.position.lat = this.place.geometry.location.lat();
                    this.position.lng = this.place.geometry.location.lng();
                    this.address.geo_lat = this.place.geometry.location.lat();
                    this.address.geo_long = this.place.geometry.location.lng();
                    this.center = this.position;
                }
                this.place = null;
            },
            editLocation: function (item) {
                this.dialog = true;
                this.edit = true;
                this.address = item;
            },
            getAllAddress() {
                UserAddressService.getAllAddresses().then(res => {
                    this.areas = res.data.areas;
                    this.cities = res.data.cities;
                    this.states = res.data.states;
                });
            },
            getCustomersAddreess() {
                WebsiteCustomer.paginate(this.userId).then(res => {
                    this.customersAddress = res.data.data;
                });
            },
            getAllWebsite() {
                websiteService
                    .getAll()
                    .then(response => {
                        this.websites = response.data.data;
                    })
                    .catch(error => {
                    });
            },

            activateOrDeactivate(item) {
                // console.log(item);
                this.customer = item;
                if (this.customer.is_active && this.customer.is_email_verified) {
                    this.customer.is_active = false;
                    this.updateCustomer();
                } else {
                    this.customer.is_active = true;
                    this.is_email_verified = true;
                    this.updateCustomer();
                }
            },

            deleteCustomer(id) {
                this.$confirm(
                    {
                        message: `Confirm delete Customer ? `,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteCustomer.delete(id).then(res => {
                                    this.$snotify.success("Customer Detail Deleted Sucessfully");
                                    this.getCustomers();
                                });
                            }
                        }
                    }
                )

            },
            openDialog() {
                this.dialog = true;
            },
            reset() {
                (this.customer = {}), this.closeDialog();
            },
            resetFilter() {
                this.search = {};
            },

            closeDialog() {
                this.dialog = false;
            },
            save() {
                this.$v.$touch();
                if (this.$v.address.$error) {
                } else {
                    if (!this.edit) {
                        this.saveLocation();
                    } else {
                        this.updateLocation();
                    }
                }
            },
            saveLocation() {
                WebsiteCustomer.create(this.userId, this.address)
                    .then(res => {
                        this.$snotify.success("Address added");
                        this.resetAddress();
                    })
                    .catch(err => {
                    });
            },
            cancel() {
                this.resetAddress();
            },
            updateLocation() {
                WebsiteCustomer.update(this.userId, this.address.id, this.address)
                    .then(res => {
                        this.$snotify.success("Address updated");
                        this.resetAddress();
                    })
                    .catch(err => {
                    });
            },
            resetAddress() {
                this.address = {
                    organisation_id: "",
                    address_line_1: "",
                    address_line_2: "",
                    // post_code: "",
                    city: "",
                    area: "",
                    state: "",
                    province: "",
                    // country_id: "",
                    geolocation_id: "",
                    geo_lat: "",
                    geo_long: "",
                    type: ""
                }
                this.edit = false;
                this.dialog = false;
                this.getCustomersAddreess();
            },
            deleteAddress(id) {
                this.$confirm(
                    {
                        message: `Confirm delete address ? `,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                WebsiteCustomer.delete(this.userId, id)
                                    .then(res => {
                                        this.$snotify.success("Address deleted");
                                        this.resetAddress();
                                    })
                                    .catch(err => {
                                    });
                            }
                        }
                    }
                )

            }
        },
        mounted() {
            this.userId = this.$store.getters.currentUser.id;
            this.getAllWebsite();
            this.getCountries();
            this.getCustomersAddreess();
        },
        computed: {
            userId() {
                return this.$route.params.userId;
            },
            fields() {
                if (!this.model) return [];

                return Object.keys(this.model).map(key => {
                    return {
                        key,
                        value: this.model[key] || "n/a"
                    };
                });
            },
            items() {
                return this.entries.map((entry, index) => {
                    const Description = entry.area + ", " + entry.city + ", " + entry.state;
                    return Object.assign({}, entry, {Description});
                });
            }
        },
        watch: {
            $route() {
                this.search.status = this.$route.params.status;
                this.getCustomers();
            },
            search(val) {
                // Items have already been loaded
                if (this.items.length > 0) return;

                // Items have already been requested
                if (this.isLoading) return;

                this.isLoading = true;

                // Lazily load input items
                // fetch("https://api.publicapis.org/entries")
                UserAddressService.getAddress(val)
                    .then(res => {
                        const {count, result} = res.data;
                        this.count = count;
                        this.entries = result;
                    })
                    .catch(err => {
                    })
                    .finally(() => (this.isLoading = false));
            }
        }
    };
</script>
